<template>
  <div class="dialog-navt">
     <el-dialog
        title=""
        :visible.sync="dialogVisible"
        width="40%"
        :before-close="handleClose">
        <div class="sptell">联系电话：15902766571</div>
        <div class="sptell-person">联系人：郑先生</div>
        <span slot="footer" class="dialog-footer">
        </span>
</el-dialog>
  </div>
</template>

<script>
  export default {
    // props:{
    //   navData:{
    //     type: Array,
    //     default:[],
    //   }
    // },
    data() {
      return {
        dialogVisible: false,
      }
    },
    methods: {
      handleClose(){
          this.dialogVisible = false
      },
      openDialog(){
        this.dialogVisible = true
      },
    },
  }
</script>

<style lang="less" scoped>
  .navt {
    font-size: 12px;
    text-align: left;
    .cont {
      color: #000;
      padding: 30px 0 20px;
    }
   
   
  }
  .sptell{
     font-size:22px;
     padding-left: 30px;
   }
  .sptell-person{
     font-size: 22px;
     margin-top:24px;
     padding-left: 30px;
   }
</style>
